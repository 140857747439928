import styled from "styled-components";

const Wrapper = styled.div``;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 1;
`;

export default function ({ isTransitioning, time }) {
  return (
    <Wrapper>
      <StyledVideo id="attractor-video" autoPlay loop muted>
        <source src="/attractor/attractor.mp4" type="video/mp4" />
      </StyledVideo>
    </Wrapper>
  );
}
