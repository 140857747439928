import "./App.css";

import Videos from "./Video";
//import Motion from "./Motion";

function App() {
  return (
    <div className="App">
      <Videos />
    </div>
  );
}

export default App;
