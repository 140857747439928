//import { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div``;

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Fade = styled.div`
  background: #092652;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  animation: ${(props) => `${props.time}ms`} ${fadeInOut} forwards;
`;

export default function ({ isTransitioning, time }) {
  return <Wrapper>{isTransitioning && <Fade time={time} />}</Wrapper>;
}
