import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

const StyledAudio = styled.audio`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
`;

export default function ({ isMuted }) {
  const audioRef = useRef(null);
  const fadeRef = useState(null);
  useEffect(() => {
    clearInterval(fadeRef.current);

    let actualVolumeFadeIn = 0;
    let actualVolumeFadeOut = audioRef.current.volume;

    if (isMuted) {
      fadeRef.current = setInterval(function () {
        actualVolumeFadeOut = (parseFloat(actualVolumeFadeOut) - 0.1).toFixed(
          1
        );
        if (actualVolumeFadeOut >= 0) {
          audioRef.current.volume = actualVolumeFadeOut;
        } else {
          audioRef.current.pause();
          clearInterval(fadeRef.current);
        }
      }, 100);
    } else {
      audioRef.current.volume = actualVolumeFadeIn;
      audioRef.current.play();

      fadeRef.current = setInterval(function () {
        actualVolumeFadeIn = (parseFloat(actualVolumeFadeIn) + 0.1).toFixed(1);
        if (actualVolumeFadeIn <= 1) {
          audioRef.current.volume = actualVolumeFadeIn;
        } else {
          clearInterval(fadeRef.current);
        }
      }, 100);
    }
  }, [isMuted, audioRef.current]);
  return (
    <Wrapper>
      <StyledAudio id="attractor-video" autoPlay loop ref={audioRef}>
        <source src="/audio/bg.mp3" type="audio/mpeg" />
      </StyledAudio>
    </Wrapper>
  );
}
