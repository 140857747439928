import { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

import VIDEOS from "../videos";
import BackgroundAudio from "../BackgroundAudio";

import Attractor from "../Attractor";
import MainTransition from "../MainTransition";

import Icon from "../Icon";

const Positioner = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
`;

const START_COUNT = 0;
const TIME_INCREASE = 1;
const TIME_COMPLETE = 4;
const FADE_TIME = 3000;

const STAGES = {
  MAIN: "main",
  NEXT: "next",
  BACK: "back",
  ATTRACTOR: "attractor",
};

export default function Videos() {
  const [max] = useState(VIDEOS.length);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [src, setSource] = useState("");
  const [stage, setStage] = useState(STAGES.ATTRACTOR);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFromMainTransitioning, setIsFromMainTransitioning] = useState(false);
  const activeVideoEl = useRef(null);
  const [direction, setDirection] = useState("still");
  const [isGrabbing, setIsGrabbing] = useState(false);
  const timerRef = useRef(null);
  const attractorTimerRef = useRef(null);
  const [countdownTime, setCountdownTime] = useState(START_COUNT);
  const leapRef = useRef(null);
  const fadeTimerRef = useState(null);

  const timerIntervalRef = useRef(null);

  const resetCountdown = () => {
    clearTimeout(timerRef.current);
    clearInterval(timerIntervalRef.current);
    timerRef.current = null;
    setCountdownTime(START_COUNT);
  };

  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
    setIsGrabbing(false);
    resetCountdown();
    // if we've been navigating
    // update index and set stage to main
    if (stage === "back") {
      setActiveIndex(activeIndex === 0 ? max - 1 : activeIndex - 1);
    }
    if (stage === "next") {
      setActiveIndex(activeIndex === max - 1 ? 0 : activeIndex + 1);
    }
    // if (stage === "main") {
    //   // we can only grab
    //   setIsGrabbing(false);
    // }
  }, [activeIndex, stage, max]);

  useEffect(() => {
    if (stage !== STAGES.ATTRACTOR) {
      // when active index change === navigation completed
      setStage("main");
      // reset navigation so we can continue swiping
      setDirection("still");
      setSource(VIDEOS[activeIndex].main);
      // hide all videos but the new main

      const video = document.getElementById(`video-${activeIndex}-main`);
      video.style.opacity = 1;

      const videos = document.querySelectorAll(
        `video:not(#video-${activeIndex}-main)`
      );

      videos.forEach((v) => (v.style.opacity = 0));

      if (activeVideoEl.current) {
        activeVideoEl.current.currentTime = 0;
      }
      activeVideoEl.current = video;
    }
  }, [activeIndex]);

  useEffect(() => {
    if (stage === STAGES.ATTRACTOR) {
      // pause any video that is already playing
      if (activeVideoEl.current) {
        activeVideoEl.current.pause();
      }

      // start the attractor video
      const video = document.getElementById(`attractor-video`);
      video.style.opacity = 1;
      video.play();
      return;
    }

    // navigating back or forth
    if (stage !== STAGES.MAIN) {
      setIsPlaying(true);

      const video = document.getElementById(`video-${activeIndex}-${stage}`);
      video.style.opacity = 1;
      activeVideoEl.current = video;
      video.play();
    }
  }, [stage]);

  useEffect(() => {
    const player = activeVideoEl.current;
    if (player) player.addEventListener("ended", handleVideoEnd);
    return () => {
      if (player) player.removeEventListener("ended", handleVideoEnd);
    };
  }, [handleVideoEnd]);

  const startAttractor = useCallback(() => {
    if (stage !== STAGES.ATTRACTOR) {
      setIsTransitioning(true);

      setTimeout(() => {
        setIsPlaying(false);

        // hide all videos
        const videos = document.querySelectorAll("video");
        videos.forEach((v) => (v.style.opacity = 0));
        setStage(STAGES.ATTRACTOR);
        setActiveIndex(0);
      }, FADE_TIME / 2);

      setTimeout(() => {
        setIsTransitioning(false);
      }, FADE_TIME);
    }
  }, [stage]);

  const stopAttractor = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      const video = document.getElementById(`attractor-video`);
      video.style.opacity = 0;
      video.pause();
      setStage(STAGES.MAIN);
      setActiveIndex(1);
    }, FADE_TIME / 2);

    setTimeout(() => {
      setIsTransitioning(false);
    }, FADE_TIME);
  };

  useEffect(() => {
    const handleNavigation = (evt) => {
      console.log("evt: ", evt);
      if (isFromMainTransitioning) return;

      if (stage === "main") {
        if (isPlaying) {
          // bottom square button
          // play or pause video
          if (evt.key === "Enter" || evt.keyCode === 66 || evt.keyCode === 98) {
            if (activeVideoEl.current.paused) {
              // video has been paused
              activeVideoEl.current.volume = 1;
              activeVideoEl.current.play();
            } else {
              activeVideoEl.current.pause();
            }
          } else if (
            evt.key === "ArrowRight" ||
            evt.key === "ArrowLeft" ||
            evt.keyCode === 33 ||
            evt.keyCode === 34
          ) {
            // make sure we pause video so it can't reach 0
            setIsFromMainTransitioning(true);
            // activeVideoEl.current.pause();

            // fade out audio
            let actualVolumeFadeOut = activeVideoEl.current.volume;
            fadeTimerRef.current = setInterval(function () {
              actualVolumeFadeOut = (
                parseFloat(actualVolumeFadeOut) - 0.1
              ).toFixed(1);
              if (actualVolumeFadeOut >= 0) {
                activeVideoEl.current.volume = actualVolumeFadeOut;
              } else {
                activeVideoEl.current.pause();
                activeVideoEl.current.currentTime = 0;
                clearInterval(fadeTimerRef.current);
              }
            }, 100);

            setTimeout(() => {
              activeVideoEl.current.pause();
              handleVideoEnd();

              // FADE IN AUDIO
            }, FADE_TIME / 2);

            setTimeout(() => {
              setIsFromMainTransitioning(false);
            }, FADE_TIME);
          }

          return;
          //
        }
      }
      if (isPlaying) return;
      if (stage === STAGES.ATTRACTOR) {
        stopAttractor();
        return;
      }

      if (evt.key === "ArrowRight" || evt.keyCode === 34) {
        // get next
        // update stage to navigating
        setSource(VIDEOS[activeIndex].next);
        setStage("next");
      }

      // go back
      if (evt.key === "ArrowLeft" || evt.keyCode === 33) {
        setSource(VIDEOS[activeIndex].back);
        setStage("back");
      }

      if (evt.key === "Enter" || evt.keyCode === 66 || evt.keyCode === 98) {
        if (stage === "main") {
          setIsPlaying(true);
          activeVideoEl.current.volume = 1;
          activeVideoEl.current.play();
        }
      }
    };

    document.addEventListener("keydown", handleNavigation);
    return () => {
      document.removeEventListener("keydown", handleNavigation);
    };
  }, [activeIndex, stage, isPlaying, isFromMainTransitioning]);

  useEffect(() => {
    if (isGrabbing) {
      setIsPlaying(true);
      activeVideoEl.current.volume = 1;
      activeVideoEl.current.play();
    }
  }, [isGrabbing]);

  useEffect(() => {
    if (isPlaying) return;
    // get next
    // update stage to navigating
    if (direction === "left") {
      setSource(VIDEOS[activeIndex].back);
      setStage("back");
    }

    if (direction === "right") {
      setSource(VIDEOS[activeIndex].next);
      setStage("next");
    }
  }, [direction]);

  const startTimer = useCallback(() => {
    // if we have no active timer
    // activate it
    if (isPlaying) return;
    if (!timerRef.current) {
      // increase time every second
      timerIntervalRef.current = setInterval(() => {
        setCountdownTime((countdownTime) => countdownTime + TIME_INCREASE);
      }, TIME_INCREASE * 1000);

      // after complete
      // clear interval
      // and reset timer
      timerRef.current = setTimeout(() => {
        setIsGrabbing(true);
        resetCountdown();
      }, TIME_COMPLETE * 1000);
    }
  }, [isPlaying]);

  // this ONLY applies to attractor
  const startAttractorTimer = useCallback(() => {
    const doInactive = () => {
      if (!isPlaying) {
        startAttractor();
      }
      startAttractorTimer();
    };

    const timeoutInMiliseconds = 30000;
    //const timeoutInMiliseconds = 10000;

    attractorTimerRef.current = window.setTimeout(
      () => doInactive(),
      timeoutInMiliseconds
    );
  }, [stage, isPlaying]);

  const resetAttractorTimer = useCallback(() => {
    clearTimeout(attractorTimerRef.current);
    startAttractorTimer();
  }, [startAttractorTimer]);

  useEffect(() => {
    const Leap = window.Leap;
    leapRef.current = new Leap.Controller({
      enableGestures: true,
      loopWhileDisconnected: false,
    });

    leapRef.current.use("handEntry");

    // leapRef.current.on("frame", (frame) => {
    //   // if a video is playing we don't want to start another
    //   if (isPlaying) return;
    //   // we can only navigate when were on the main video
    //   if (stage !== "main") return;

    //   if (frame.valid && frame.data.gestures.length > 0) {
    //     frame.data.gestures.forEach(function (gesture) {
    //       if (gesture.type === "swipe") {
    //         //Classify swipe as either horizontal or vertical
    //         var isHorizontal =
    //           Math.abs(gesture.direction[0]) > Math.abs(gesture.direction[1]);

    //         if (isHorizontal) {
    //           if (gesture.direction[0] > 0) {
    //             if (direction !== "right") setDirection("right");
    //           } else {
    //             if (direction !== "left") setDirection("left");
    //           }
    //         }
    //       }
    //     });
    //   }
    // });

    let h = {};
    let t1 = "";

    leapRef.current
      .on("handFound", function (hand) {
        t1 = new Date();
        console.log("hand.found: ", hand.direction);
        h = { id: hand.id, direction: hand.direction };
        //console.log("hand found: ", hand);
        // RESET attractor timer
        if (stage === STAGES.ATTRACTOR) {
          stopAttractor();
          resetAttractorTimer();
          return;
        }
        if (isPlaying) return;
        // we can only start the main video
        if (stage !== "main") return;
        if (isGrabbing) return;

        startTimer();
      })
      .on("handLost", function (hand) {
        if (stage !== "main") return;
        if (isPlaying) return;

        const t2 = new Date();
        const diff = t2.getTime() - t1.getTime();
        console.log("diff in seconds: ", diff / 1000);
        //console.log("hand lost: ", hand.direction);
        console.log("time visible leapmotion: ", hand.timeVisible);
        //Classify swipe as either horizontal or vertical
        var isHorizontal =
          Math.abs(hand.direction[0]) > Math.abs(hand.direction[1]);
        console.log("isHorizontal: ", isHorizontal);

        if (isHorizontal && diff / 1500 < 1) {
          if (hand.direction[0] > 0) {
            if (direction !== "right") {
              console.log("right");
              setDirection("right");
            }
          } else {
            if (direction !== "left") {
              console.log("left");
              setDirection("left");
            }
          }
        }
        clearTimeout(timerRef.current);
        resetCountdown();
      });

    leapRef.current.connect();

    return () => {
      leapRef.current.disconnect();
      leapRef.current = null;
    };
  }, [isPlaying, stage, isGrabbing]);

  useEffect(() => {
    document.addEventListener("keydown", () => resetAttractorTimer(), false);
    startAttractorTimer();

    return () => {
      window.clearTimeout(attractorTimerRef.current);
    };
  }, [startAttractorTimer, resetAttractorTimer]);

  return (
    <Positioner>
      {VIDEOS.map((v, i) => {
        return (
          <div key={`videos-${i}`} id={`videos-${i}`}>
            <StyledVideo id={`video-${i}-main`}>
              <source src={v.main} type="video/mp4" />
            </StyledVideo>
            <StyledVideo id={`video-${i}-back`} muted>
              <source src={v.back} type="video/mp4" />
            </StyledVideo>
            <StyledVideo id={`video-${i}-next`} muted>
              <source src={v.next} type="video/mp4" />
            </StyledVideo>
          </div>
        );
      })}

      <Attractor isPlaying={stage === STAGES.ATTRACTOR} />

      <Icon
        isPlay={countdownTime > 1}
        number={countdownTime}
        timeComplete={TIME_COMPLETE - 1}
        isAttractor={stage === STAGES.ATTRACTOR}
        isHidden={isPlaying && stage === STAGES.MAIN}
        isIdle={!isPlaying && stage === STAGES.MAIN}
      />

      <MainTransition
        isTransitioning={isFromMainTransitioning || isTransitioning}
        time={FADE_TIME}
      />
      <BackgroundAudio isMuted={isPlaying && stage === STAGES.MAIN} />
    </Positioner>
  );
}
