// export default [
//   {
//     main: "/videos/hotel/main.mp4",
//     back: "/videos/hotel/hotel-to-hospital.mp4",
//     next: "/videos/hotel/hotel-to-port.mp4",
//   },
//   {
//     main: "/videos/port/main.mp4",
//     back: "/videos/port/port-to-hotel.mp4",
//     next: "/videos/port/port-to-hospital.mp4",
//   },
//   {
//     main: "/videos/hospital/main.mp4",
//     back: "/videos/hospital/hospital-to-port.mp4",
//     next: "/videos/hospital/hospital-to-hotel.mp4",
//   },
// ];

export default [
  {
    main: "/videos/hospitality/main.mp4",
    back: "/videos/hospitality/hospitality-to-medicine.mp4",
    next: "/videos/hospitality/hospitality-to-freight.mp4",
  },
  {
    main: "/videos/freight/main.mp4",
    back: "/videos/freight/freight-to-hospitality.mp4",
    next: "/videos/freight/freight-to-medicine.mp4",
  },
  {
    main: "/videos/medicine/main.mp4",
    back: "/videos/medicine/medicine-to-freight.mp4",
    next: "/videos/medicine/medicine-to-hospitality.mp4",
  },
];
