import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import play from "./HOVER-icon-CTA.gif";
import idle from "./SWIPE-icon-CTA.gif";
import attractor from "./attractor-loop-CTA.gif";

import Circle from "./Circle";
const Wrapper = styled.div`
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  height: 350px;
`;

const Inner = styled.div`
  position: relative;
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
`;

const Icon = styled.img`
  width: 350px;
  height: 350px;
`;

export default function ({
  isPlay,
  isIdle,
  isAttractor,
  number,
  timeComplete,
  isHidden,
}) {
  const timeRef = useRef(null);
  const [src, setSrc] = useState("");

  useEffect(() => {
    clearTimeout(timeRef.current);
    let s = "";
    if (isPlay) {
      s = play;
    } else if (isAttractor) {
      s = attractor;
    } else if (isIdle) {
      if (src === play) {
        s = play;
        timeRef.current = setTimeout(() => {
          s = idle;
          setSrc(s);
        }, 2000);
      } else {
        s = idle;
      }
    }

    setSrc(s);
  }, [isPlay, isAttractor, isIdle]);

  return (
    <Wrapper>
      <Inner isHidden={isHidden}>
        <Circle progress={isPlay ? (number / timeComplete) * 100 : 0} />
        {src && <Icon src={src} alt="" />}
      </Inner>
    </Wrapper>
  );
}
