import styled from "styled-components";

const Wrapper = styled.svg`
  position: absolute;
  top: -25px;
  left: -17px;
  overflow: visible;
`;

const CircleWithAnimation = styled.circle`
  transition: stroke-dashoffset 1s linear;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  opacity: 1;
`;

// const Circle = styled.circle`
//   transform: rotate(-90deg);
//   transform-origin: 50% 50%;
//   opacity: 0.6;
// `;

export default function CircleAnimated({ progress = 0 }) {
  const radius = 192;
  const stroke = 8;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <Wrapper height={radius * 2} width={radius * 2}>
      <CircleWithAnimation
        stroke="white"
        fill="transparent"
        strokeWidth={8}
        strokeDasharray={circumference + " " + circumference}
        style={{ strokeDashoffset }}
        stroke-width={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </Wrapper>
  );
}
